import { DataExchangeService } from './../services/data-exchange.service';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import { ApiService } from '../services/api.service';
import { ReactiveFormsModule, FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from '../authentication/authentication.service';
import { HeaderComponent } from '../header/header.component';
import { CommonModule } from '@angular/common';
import { NotInKscopeComponent } from '../not-in-kscope/not-in-kscope.component';
import { OptedInComponent } from '../opted-in/opted-in.component';
import { IFindCustomerMktProgram } from '../interfaces/find-customer-mkt-program';
import { ICustomer, ISoldToAddress } from '../interfaces/customer';
import { LoadingSpinnerComponent } from '../loading-spinner/loading-spinner.component';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    HeaderComponent,
    CommonModule,
    NotInKscopeComponent,
    OptedInComponent,
    LoadingSpinnerComponent
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class HomeComponent {
  public optInStatus: string = '';
  public optInData: IFindCustomerMktProgram = {} as IFindCustomerMktProgram;
  public cantFindSoldTo: boolean = false;
  public soldToSalesOrg: string = '';
  public soldToAddress: ISoldToAddress = {} as ISoldToAddress;
  public loadingSpinner: string = '';

  constructor(
    public apiService: ApiService,
    public authService: AuthenticationService,
    private formBuilder: FormBuilder,
    private dataExchangeService: DataExchangeService
  ) {}

  ngOnInit(): void {
    this.dataExchangeService.currentOptInStatus.subscribe((status) => {
      this.optInStatus = status;
    });
    this.dataExchangeService.currentLoadingSpinnerStatus.subscribe((status) => {
      this.loadingSpinner = status;
    });
  }

  public findSoldToForm = this.formBuilder.group({
    findSoldTo: ['', Validators.required],
  });

  // find sold to function - if we get optInStatus - call getUserCredentials
  public async findSoldTo() {
    var soldToNumber = this.findSoldToForm.get('findSoldTo')!.value ?? '';
  
    if (soldToNumber != '') {
      try {
        // get user credentials for salesOrg
        const userCredentials = await firstValueFrom(this.apiService.getUserCredentials(soldToNumber));
        const salesOrg = userCredentials?.userCredential?.salesOrg;
  
        if (salesOrg) {
          this.cantFindSoldTo = false;
  
          // get customer mkt program - for optInStatus and date to display
          this.optInData = await firstValueFrom(this.apiService.findCustomerMktProgram(soldToNumber, salesOrg)) ?? {} as IFindCustomerMktProgram;
          this.optInStatus = this.optInData?.optInStatus ?? '';
  
          if (this.optInStatus === '') {
            this.cantFindSoldTo = true;
          } else {
            // soldToNumber needs leading 000 for getSoldToData call
            soldToNumber = ('0000000000' + soldToNumber).slice(-10);
  
            // get soldTo data for address and name to display
            const soldToData = await firstValueFrom(this.apiService.getSoldToData(soldToNumber, salesOrg));
            this.soldToAddress = soldToData?.customers?.find((x: ICustomer) => x.accountNumber === soldToNumber) || ({} as ICustomer);
  
            this.cantFindSoldTo = !this.soldToAddress;
          }
        } else {
          this.cantFindSoldTo = true;
          this.optInStatus = '';
        }
      } catch (error) {
        console.error(error);
        this.cantFindSoldTo = true;
        this.optInStatus = '';
      }
    }
  }
}
