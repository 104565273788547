<div class="opted-in-container">
  <div class="left">
    <h3 *ngIf="soldToAddress.customerName">{{soldToAddress.customerName}}</h3>
    <p *ngIf="optInData.optInDate" class="opted-in-date">Opted in on {{optInData.optInDate | date:'shortDate'}}</p>
  </div>
  <div class="right">
    <address>
      <span *ngIf="soldToAddress.addr1">{{soldToAddress.addr1}}<br/></span>
      <span *ngIf="soldToAddress.addr2">{{soldToAddress.addr2}}<br/></span>
      <span *ngIf="soldToAddress.addr3">{{soldToAddress.addr3}}<br/></span>
      <span *ngIf="soldToAddress.city">{{soldToAddress.city}}</span>, <span *ngIf="soldToAddress.state">{{soldToAddress.state}}</span>  <span *ngIf="soldToAddress.postalCode">{{soldToAddress.postalCode}}</span><br />
      <span *ngIf="soldToAddress.phone">{{soldToAddress.phone}}</span>
    </address>
    <div class="opt-out">
      <button class="opt-out-btn" (click)="optOut()">Opt Out</button>
    </div>
    <div class="modify-status">
      <button class="modify-status-btn" (click)="modifyEyefinityStatus()">Modify Eyefinity Status</button>
    </div>
  </div>
</div>

<div class="business-accounts" *ngIf="businessAccounts.length > 0">

    <ul>
      <li class="business-accounts-header">
        <h3>EFAN Numbers</h3>
        <span>enable</span>
        <span>disable</span>
      </li>
      <li class="efan-number" *ngFor="let account of businessAccounts; index as i">
        <span>{{account.eyefinityAccountNumber}} - {{account.street}}</span> <!-- {{account.accountName}} -->
        <input type="radio" id="enable-{{account.eyefinityAccountNumber}}" (change)="updateEFANStatus(i, true)" [checked]="statusE[i]" name="{{account.eyefinityAccountNumber}}">
        <input type="radio" id="disable-{{account.eyefinityAccountNumber}}" (change)="updateEFANStatus(i, false)" [checked]="statusD[i]" name="{{account.eyefinityAccountNumber}}">
      </li>
    </ul>
    <div class="btn-group">
      <button class="updateBA-btn" (click)="saveEFANStatus()">Update</button> 
      <button class="resetBA-btn" (click)="resetStatus()">Reset</button> 
    </div>

</div> 