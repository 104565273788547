<div class="opted-in-container">
  <div class="left">
    <h3 *ngIf="soldToAddress.customerName">{{soldToAddress.customerName}}</h3>
  </div>
  <div class="right">
    <address>
      <span *ngIf="soldToAddress.addr1">{{soldToAddress.addr1}}<br/></span>
      <span *ngIf="soldToAddress.addr2">{{soldToAddress.addr2}}<br/></span>
      <span *ngIf="soldToAddress.addr3">{{soldToAddress.addr3}}<br/></span>
      <span *ngIf="soldToAddress.city">{{soldToAddress.city}}</span>, <span *ngIf="soldToAddress.state">{{soldToAddress.state}}</span>  <span *ngIf="soldToAddress.postalCode">{{soldToAddress.postalCode}}</span><br />
      <span *ngIf="soldToAddress.phone">{{soldToAddress.phone}}</span>
    </address>
    <div class="opt-in">
      <button class="opt-in-btn" (click)="optIn()">Opt In</button>
    </div>
  </div>
</div>