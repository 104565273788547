import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../authentication/authentication.service';
import { environment } from '../../environments';
import { Observable } from 'rxjs';
import { IFindCustomerMktProgram } from '../interfaces/find-customer-mkt-program';
import { IUserCredentials } from '../interfaces/user-credentials';
import { IEFANStatus, IEFKScopeIntegrationStatus } from '../interfaces/kscope-int-status';
import { IProvider } from '../interfaces/business-accounts';
import { IServiceStatus, IServiceStatusUpdate } from '../interfaces/service-status';
import { ICustomers } from '../interfaces/customer';
import { IUpdateMarchonKscope } from '../interfaces/update-marchon-kscope';

export const EFKsopeStatusEndpoints = {
  getUserCredentials: '/OrganizationServicesWeb/getUserCredential2JS/invoke',
  findCustomerMktProgram: '/CustomerMarketingProgramsWeb/getCustomerMarketingProgram/getCustomerMktProgram',
  shippingAddressRelativeUrl: '/CustomerServicesWebWeb/GetCustomerShipToList/invoke',
  kscopeUpdateStatus: '/CustomerMarketingProgramsWeb/updateCustomerMarketingProgram/updateCustomerMktProgram',
  getEFANNumbers: '/CustomerMarketingProgramsWeb/Providers/provider',
  getKscopeEFANIntegrationStatus: '/CustomerMarketingProgramsWeb/EyefinityIntegrationPreferenceExport/getKscopeEFANIntegrationStatus',
  saveKscopeEFANIntegrationStatus: '/CustomerMarketingProgramsWeb/EyefinityIntegrationPreferenceExport1/saveKscopeEFANIntegrationStatus',
  updateMarchonKscopePreference: '/proc-kaleyedoscope/api/updateMarchonKscopePreference'
};

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    private http: HttpClient,
    protected readonly authService: AuthenticationService
  ) {}

  public getUserCredentials(soldTo: string): Observable<IUserCredentials> {
    const url = environment.api + EFKsopeStatusEndpoints.getUserCredentials;
    const params = {
      userCredential: {},
      userID: soldTo,
    };

    return this.http.post<IUserCredentials>(url, params, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this.authService.userToken,
      },
    });
  }
  
  public findCustomerMktProgram(soldTo: string, salesOrg: string): Observable<IFindCustomerMktProgram> {
    const url = environment.api + EFKsopeStatusEndpoints.findCustomerMktProgram;
    const params = {
      userCredential: {},
      soldTo,
      salesOrg,
    };

    return this.http.post<IFindCustomerMktProgram>(url, params, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this.authService.userToken,
      },
    });
  }

  public getSoldToData(accountNumber: string, salesOrg: string): Observable<ICustomers> {
    const url = environment.api + EFKsopeStatusEndpoints.shippingAddressRelativeUrl;
    const params = {
      userCredential:{},
      accountNumber,
      salesOrg,
      sapDivision:99, 
      sapDistribution:10
    };

    return this.http.post<ICustomers>(url, params, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this.authService.userToken,
      },
    });
  }

  public updateStatus(params: IFindCustomerMktProgram): Observable<IServiceStatus> {
    const url = environment.api + EFKsopeStatusEndpoints.kscopeUpdateStatus;

    return this.http.post<IServiceStatus>(url, params, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this.authService.userToken,
      },
    });
  }

  public getEFANNumbers(soldTo: string): Observable<IProvider> {
    const url = environment.api + EFKsopeStatusEndpoints.getEFANNumbers;
    const params = {
      key: "marchonId",
      value: soldTo
    };

    return this.http.post<IProvider>(url, params, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this.authService.userToken,
      },
    });
  }

  public getKscopeEFANIntegrationStatus(efanNumber: string): Observable<IEFANStatus> {
    const url = environment.api + EFKsopeStatusEndpoints.getKscopeEFANIntegrationStatus;
    const params = {
      efan: efanNumber
    };

    return this.http.post<IEFANStatus>(url, params, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this.authService.userToken,
      },
    });
  }

  public saveKscopeEFANIntegrationStatus(obj: IEFKScopeIntegrationStatus): Observable<any>{
    const url = environment.api + EFKsopeStatusEndpoints.saveKscopeEFANIntegrationStatus;
    const params = obj;

    return this.http.post(url, params, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this.authService.userToken,
      },
    });
  }

  public updateMarchonKscopePreference(obj: IUpdateMarchonKscope): Observable<IServiceStatusUpdate>{
    const url = environment.muleHost + EFKsopeStatusEndpoints.updateMarchonKscopePreference;
    const params = obj;

    return this.http.post<IServiceStatusUpdate>(url, params, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this.authService.userToken,
      },
    });
  }
}
