import { AuthenticationService } from './../authentication/authentication.service';
import { Component, Input, OnInit } from '@angular/core';
import { IFindCustomerMktProgram } from '../interfaces/find-customer-mkt-program';
import { CommonModule, DatePipe } from '@angular/common';
import { ApiService } from '../services/api.service';
import { take, firstValueFrom } from 'rxjs';
import { DataExchangeService } from '../services/data-exchange.service';
import { ISoldToAddress } from '../interfaces/customer';
import { IBusinessAccounts } from '../interfaces/business-accounts';
import { ReactiveFormsModule } from '@angular/forms';
import { IEFKScopeIntegrationStatus } from '../interfaces/kscope-int-status';
import { IUpdateMarchonKscope } from '../interfaces/update-marchon-kscope';

@Component({
  selector: 'app-opted-in',
  standalone: true,
  imports: [DatePipe, CommonModule, ReactiveFormsModule],
  templateUrl: './opted-in.component.html',
  styleUrl: './opted-in.component.scss',
})
export class OptedInComponent implements OnInit {
  public statusE: boolean[] = [];
  public statusD: boolean[] = [];
  public businessAccounts: IBusinessAccounts[] = [];
  @Input() optInData: IFindCustomerMktProgram = {} as IFindCustomerMktProgram;
  @Input() soldToAddress: ISoldToAddress = {} as ISoldToAddress;

  constructor(
    public apiService: ApiService,
    private dataExchangeService: DataExchangeService,
    protected readonly authService: AuthenticationService
  ) {}

  ngOnInit(): void {}

  public optOut() {
    const params: IFindCustomerMktProgram = {
      soldTo: `${this.optInData.soldTo}`,
      salesOrg: `${this.optInData.salesOrg}`,
      mktProgramName: `${this.optInData.mktProgramName}`,
      eligibility: `${this.optInData.eligibility}`,
      optInStatus: `N`,
      autoShip: `${this.optInData.autoShip}`,
      optInDate: '',
    };

    this.apiService
      .updateStatus(params)
      .pipe(take(1))
      .subscribe((res: any) => {
        if (
          res.serviceStatus.resultCode === -1111 ||
          res.serviceStatus.resultCode === 0
        ) {
          this.dataExchangeService.updateOptInStatus('Updated');
        }
      });
  }

  public async modifyEyefinityStatus() {
    try {
      const res = await firstValueFrom(this.apiService.getEFANNumbers(this.optInData.soldTo));
      if (res.provider.providerAccounts[0].businessAccounts.length > 0) {
        this.businessAccounts = res.provider.providerAccounts[0].businessAccounts.filter(account => 'eyefinityAccountNumber' in account);
        this.statusE = Array(this.businessAccounts.length).fill(false);
        this.statusD = Array(this.businessAccounts.length).fill(false);
        
        // get EFAN Status - Lenny doesn't want this now
        // for (const [i, account] of this.businessAccounts.entries()) {
        //   const statusRes = await firstValueFrom(this.apiService.getKscopeEFANIntegrationStatus(account.eyefinityAccountNumber));
        //   if (statusRes.serviceStatus.resultCode === 0) {
        //     this.statusE[i] = statusRes.EFKscopeIntegrationStatus?.EFKscopeIntegrationStatus === 'Y';
        //     this.statusD[i] = statusRes.EFKscopeIntegrationStatus?.EFKscopeIntegrationStatus === 'N';
        //   }
        // }
      }
    } catch (error) {
      console.error('Error modifying Eyefinity status:', error);
    }
  }
  

  public updateEFANStatus(i: number, status: boolean) {
    this.statusE[i] = status;
    this.statusD[i] = !status;

    // console.log(this.statusE);
    // console.log(this.statusD);
    // console.log(this.businessAccounts[i].eyefinityAccountNumber);
    // console.log(this.businessAccounts[i].eyefinityAccountNumber + ' enabled status = ' + this.statusE[i]);
    // console.log(this.businessAccounts[i].eyefinityAccountNumber + ' disabled status = ' + this.statusD[i]);
  }

  public saveEFANStatus() {
    const selectedAccounts: { account: IBusinessAccounts; status: string }[] = [];
    for (let i = 0; i < this.businessAccounts.length; i++) {
      if (this.statusE[i]) {
        selectedAccounts.push({ account: this.businessAccounts[i], status: 'Y' });
      } else if (this.statusD[i]) {
        selectedAccounts.push({ account: this.businessAccounts[i], status: 'N' });
      }
    }
    // console.log(selectedAccounts); // all business accounts that have changed status

    // call save service and send the status of the checkbox
    selectedAccounts.forEach(async (a) => {
      const obj: IUpdateMarchonKscope = {
        accountType: a.account.accountType,
        accountName: a.account.accountName,
        mdmAccountKey: a.account.mdmAccountKey,
        mdmLocKey: a.account.mdmLocationKey,
        opticsId: a.account.opticsId,
        altairId: a.account.altairId,
        eyefinityPracticeSolutionId: a.account.eyefinityPracticeSolutionId,
        eyefinityAccountNumber: a.account.eyefinityAccountNumber,
        street: a.account.street,
        city: a.account.city,
        state: a.account.state,
        zip: a.account.zip,
        eventSource: 'KSCOPE_BIZDEV',
        kscopeOptIn: a.status === 'Y',
        kscopeEyefinityIntegration: a.status === 'Y'
      }

      // call update kscope status service
      const res2 = await firstValueFrom(this.apiService.updateMarchonKscopePreference(obj));
      if (res2.status === 'success') {
        // console.log(res2);
        this.dataExchangeService.updateOptInStatus('Updated');

        // get token for fullname and principal values - from pingFed
        // var token = JSON.parse(window.atob(this.authService.userToken.split(".")[1]));

        // const params: IEFKScopeIntegrationStatus = {
        //   eyefinityAccountNumber: a.account.eyefinityAccountNumber,
        //   eyefinityPracticeSolutionId: a.account.eyefinityPracticeSolutionId,
        //   mdmActKey: a.account.mdmAccountKey,
        //   mdmLocKey: a.account.mdmLocationKey,
        //   vspEmployeeId: token.principal, // account.vspEmployeeId, new input - get from token 'principal'
        //   EFKscopeIntegrationStatus: a.status,
        //   practiceRequestedBy: token.fullname, // account.practiceRequestedBy, new input - get from token
        // }
  
        // const res = await firstValueFrom(this.apiService.saveKscopeEFANIntegrationStatus(params));  
        // if (res.serviceStatus.resultCode === 0) {
          // console.log(res);
        // }
      }

    });
  }

  public resetStatus() {
    this.statusE.fill(false);
    this.statusD.fill(false);
  }
}
