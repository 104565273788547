<div class="wrapper">
  <!-- <app-header></app-header> -->
  <div class="container">
    <div class="form-wrapper">
      <form [formGroup]="findSoldToForm" (ngSubmit)="findSoldTo()">
        <div class="form-group-wrapper">
          <input type="text" class="sold-to-input" id="sold-to-input" aria-describedby="sold to input"
            placeholder="1506214" formControlName="findSoldTo">
          <button class="sold-to-btn" type="submit" [disabled]="!findSoldToForm.valid">Find Sold To</button>
        </div>
        <p class="error"
          *ngIf="findSoldToForm.controls['findSoldTo'].invalid && (findSoldToForm.controls['findSoldTo'].dirty || findSoldToForm.controls['findSoldTo'].touched)">
          'Find Sold To' input is required.
        </p>
      </form>
    </div>
    @if(cantFindSoldTo){
    <div class="cant-find-soldto">
      <p class="error">Sorry, we can't find your Sold To number.</p>
      <p class="error">Please call Customer Service at 1-800-269-3666</p>
    </div>
    }
  </div>
  <app-not-in-kscope [optInData]="optInData" [soldToAddress]="soldToAddress" *ngIf="optInStatus === 'N'"></app-not-in-kscope>
  <app-opted-in [optInData]="optInData" [soldToAddress]="soldToAddress" *ngIf="optInStatus === 'Y'"></app-opted-in>
  <div class="updated-status" *ngIf="optInStatus === 'Updated'">
    <p class="success">Your status has been updated.</p>
  </div>
</div> 
<app-loading-spinner *ngIf="loadingSpinner === 'true'"></app-loading-spinner>
